export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const USER_LOGIN_RESET = "USER_LOGIN_RESET";
export const USER_LOGOUT = "USER_LOGOUT";

export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL";
export const USER_REGISTER_RESET = "USER_REGISTER_RESET";

export const USER_UPDATE_PROFILE_REQUEST = "USER_UPDATE_PROFILE_REQUEST";
export const USER_UPDATE_PROFILE_SUCCESS = "USER_UPDATE_PROFILE_SUCCESS";
export const USER_UPDATE_PROFILE_FAIL = "USER_UPDATE_PROFILE_FAIL";
export const USER_UPDATE_PROFILE_RESET = "USER_UPDATE_PROFILE_RESET";

export const USER_DELETE_PROFILE_REQUEST = "USER_DELETE_PROFILE_REQUEST";
export const USER_DELETE_PROFILE_SUCCESS = "USER_DELETE_PROFILE_SUCCESS";
export const USER_DELETE_PROFILE_FAIL = "USER_DELETE_PROFILE_FAIL";
export const USER_DELETE_PROFILE_RESET = "USER_DELETE_PROFILE_RESET";

export const USER_CHANGE_PASSWORD_REQUEST = "USER_CHANGE_PASSWORD_REQUEST";
export const USER_CHANGE_PASSWORD_SUCCESS = "USER_CHANGE_PASSWORD_SUCCESS";
export const USER_CHANGE_PASSWORD_FAIL = "USER_CHANGE_PASSWORD_FAIL";
export const USER_CHANGE_PASSWORD_RESET = "USER_CHANGE_PASSWORD_RESET";

export const GET_FAVORITE_MOVIES_REQUEST = "GET_FAVORITE_MOVIES_REQUEST";
export const GET_FAVORITE_MOVIES_SUCCESS = "GET_FAVORITE_MOVIES_SUCCESS";
export const GET_FAVORITE_MOVIES_FAIL = "GET_FAVORITE_MOVIES_FAIL";
export const GET_FAVORITE_MOVIES_RESET = "GET_FAVORITE_MOVIES_RESET";

export const DELETE_FAVORITE_MOVIES_REQUEST = "DELETE_FAVORITE_MOVIES_REQUEST";
export const DELETE_FAVORITE_MOVIES_SUCCESS = "DELETE_FAVORITE_MOVIES_SUCCESS";
export const DELETE_FAVORITE_MOVIES_FAIL = "DELETE_FAVORITE_MOVIES_FAIL";
export const DELETE_FAVORITE_MOVIES_RESET = "DELETE_FAVORITE_MOVIES_RESET";

export const GET_ALL_USERS_REQUEST = "GET_ALL_USERS_REQUEST";
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";
export const GET_ALL_USERS_FAIL = "GET_ALL_USERS_FAIL";
export const GET_ALL_USERS_RESET = "GET_ALL_USERS_RESET";

export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";
export const DELETE_USER_RESET = "DELETE_USER_RESET";

export const LIKE_MOVIE_REQUEST = "LIKE_MOVIE_REQUEST"
export const LIKE_MOVIE_SUCCESS = "LIKE_MOVIE_SUCCESS"
export const LIKE_MOVIE_FAIL = "LIKE_MOVIE_FAIL"
export const LIKE_MOVIE_RESET = "LIKE_MOVIE_RESET"
