export const MOVIES_LIST_REQUEST = "MOVIES_LIST_REQUEST";
export const MOVIES_LIST_SUCCESS = "MOVIES_LIST_SUCCESS";
export const MOVIES_LIST_FAIL = "MOVIES_LIST_FAIL";

export const MOVIES_RANDOM_REQUEST = "MOVIES_RANDOM_REQUEST";
export const MOVIES_RANDOM_SUCCESS = "MOVIES_RANDOM_SUCCESS";
export const MOVIES_RANDOM_FAIL = "MOVIES_RANDOM_FAIL";

export const MOVIE_DETAILS_REQUEST = "MOVIE_DETAILS_REQUEST";
export const MOVIE_DETAILS_SUCCESS = "MOVIE_DETAILS_SUCCESS";
export const MOVIE_DETAILS_FAIL = "MOVIE_DETAILS_FAIL";
export const MOVIE_DETAILS_RESET = "MOVIE_DETAILS_RESET";

export const MOVIE_TOP_RATED_REQUEST = "MOVIE_TOP_RATED_REQUEST";
export const MOVIE_TOP_RATED_SUCCESS = "MOVIE_TOP_RATED_SUCCESS";
export const MOVIE_TOP_RATED_FAIL = "MOVIE_TOP_RATED_FAIL";

export const CREATE_REVIEW_REQUEST = "CREATE_REVIEW_REQUEST";
export const CREATE_REVIEW_SUCCESS = "CREATE_REVIEW_SUCCESS";
export const CREATE_REVIEW_FAIL = "CREATE_REVIEW_FAIL";
export const CREATE_REVIEW_RESET = "CREATE_REVIEW_RESET";
